import React, { useEffect, useState } from "react";
import { FormItem, CrudField } from "common/types";
import { Button, Flex, Popover, Table, TablePaginationConfig } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import {
  DeleteEntryButton,
  DeleteEntryPopconfirm,
  InsertEntryButton,
  TableContainer,
} from "common/components/styled";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { GenericForm } from "common/components";
import { SortOrder } from "antd/es/table/interface";

type TableProps<TableType extends object, FormType extends object> = {
  idKey: keyof TableType;
  fields: Array<CrudField<TableType, FormType>>;
  dataSource: Array<TableType>;
  pagination?: false | TablePaginationConfig;
  loading?: boolean;
  sticky?: boolean;
  scroll?: {
    x?: number | true | string;
    y?: number | string;
    scrollToFirstRowOnChange?: boolean;
  };
  size?: SizeType;
  formTitleIcon: React.ReactNode;
  onCreateEntry: (entity: FormType) => Promise<void>;
  onReadEntry: (id: number) => Promise<FormType>;
  onUpdateEntry: (entity: FormType, id: number) => Promise<void>;
  onDeleteEntry: (id: number) => Promise<void>;
};

export const GenericCrudTable = <
  TableType extends object,
  FormType extends object
>({
  idKey,
  fields,
  dataSource,
  pagination,
  loading,
  sticky,
  scroll,
  size,
  formTitleIcon,
  onCreateEntry,
  onReadEntry,
  onUpdateEntry,
  onDeleteEntry,
}: TableProps<TableType, FormType>) => {
  const [formTitle, setFormTitle] = useState<string>("");
  const [formItems, setFormItems] = useState<Array<FormItem<FormType>>>([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [tableHeight, setTableHeight] = useState(100);
  const [cellHeight, setCellHeight] = useState(100);
  const [editID, setEditID] = useState<number | null>(null);

  useEffect(() => {
    const resizeObserverTable = new ResizeObserver((event) => {
      setTableHeight(event[0].contentBoxSize[0].blockSize);
    });
    const resizeObserverCell = new ResizeObserver((event) => {
      setCellHeight(event[0].contentBoxSize[0].blockSize);
    });

    resizeObserverTable.observe(
      document.getElementById("tableContainer") as Element
    );
    resizeObserverCell.observe(
      document.getElementsByClassName("ant-table-cell")[0] as Element
    );
  }, []);

  const handleFormOpen = async (title: string, id?: number) => {
    if (id !== undefined) {
      const entity: FormType = await onReadEntry(id);
      const formItems: Array<FormItem<FormType>> = fields
        .filter((field) => field.formOptions !== undefined)
        .map(
          (field) =>
            ({
              ...field.formOptions,
              hidden:
                field.formOptions?.hidden === "update" ||
                field.formOptions?.hidden === true
                  ? true
                  : false,
              initialValue: entity[field.dataIndex as keyof FormType],
              name: field.dataIndex,
              label: field.title,
            } as FormItem<FormType>)
        );
      setFormItems(formItems);
      setEditID(id);
    } else {
      const formItems: Array<FormItem<FormType>> = fields
        .filter((field) => field.formOptions !== undefined)
        .map(
          (field) =>
            ({
              ...field.formOptions,
              hidden:
                field.formOptions?.hidden === "create" ||
                field.formOptions?.hidden === true
                  ? true
                  : false,
              name: field.dataIndex,
              label: field.title,
            } as FormItem<FormType>)
        );
      setFormItems(formItems);
      setEditID(null);
    }
    setFormTitle(title);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
  };

  const handleFormSubmit = async (entity: FormType) => {
    if (editID) {
      await onUpdateEntry(entity, editID);
    } else {
      await onCreateEntry(entity);
    }
    handleFormClose();
  };

  return (
    <TableContainer id="tableContainer">
      <GenericForm<FormType>
        titleIcon={formTitleIcon}
        title={formTitle}
        formItems={formItems}
        visible={isFormVisible}
        onCancel={handleFormClose}
        onSubmit={handleFormSubmit}
      />
      <Table<TableType>
        columns={[
          ...fields
            .filter((item) => item.tableOptions !== undefined)
            .map((item) => {
              return {
                ...item.tableOptions,
                key: String(item.dataIndex),
                title: item.title,
                dataIndex: String(item.dataIndex),
                filters: item.tableOptions!.filters,
                onFilter:
                  item.tableOptions!.onFilter || item.tableOptions!.filters
                    ? (value: boolean | React.Key, record: any) => {
                        const renderedValue = item.tableOptions!.render
                          ? item.tableOptions!.render(
                              record[item.dataIndex],
                              record,
                              0
                            )
                          : String(record[item.dataIndex]);
                        return String(renderedValue).includes(String(value));
                      }
                    : undefined,
                sortDirections:
                  item.tableOptions!.sortDirections ||
                  (["ascend", "descend", undefined] as SortOrder[]),
                defaultSortOrder:
                  item.tableOptions!.defaultSortOrder ||
                  item.dataIndex === idKey
                    ? ("ascend" as SortOrder)
                    : undefined,
                sorter:
                  item.tableOptions!.sorter === true
                    ? (a: any, b: any) => {
                        const aValue = item.tableOptions!.render
                          ? item.tableOptions!.render(a[item.dataIndex], a, 0)
                          : a[item.dataIndex];
                        const bValue = item.tableOptions!.render
                          ? item.tableOptions!.render(b[item.dataIndex], b, 0)
                          : b[item.dataIndex];
                        if (
                          typeof aValue === "number" &&
                          typeof bValue === "number"
                        ) {
                          return aValue - bValue;
                        } else {
                          return String(aValue).localeCompare(String(bValue));
                        }
                      }
                    : item.tableOptions!.sorter,
                width:
                  item.dataIndex === idKey
                    ? "3.5rem"
                    : item.tableOptions!.width,
              };
            }),
          ...[
            {
              title: (
                <Popover content="Insert new entry" trigger="hover">
                  <InsertEntryButton
                    icon={<PlusOutlined style={{ fontSize: 20 }} />}
                    onClick={async () => await handleFormOpen("Create entry")}
                    type="primary"
                  />
                </Popover>
              ),
              key: "form-actions",
              render: (record: TableType) => (
                <Flex justify="flex-end">
                  <Popover content="Edit entry" trigger="hover">
                    <Button
                      icon={<EditOutlined />}
                      onClick={async () =>
                        await handleFormOpen(
                          "Edit entry",
                          Number(record[idKey])
                        )
                      }
                    />
                  </Popover>
                  <Popover content="Delete entry" trigger="hover">
                    <DeleteEntryPopconfirm
                      title="Delete the entry"
                      description={`Are you sure to delete this entry?`}
                      onConfirm={async () =>
                        await onDeleteEntry(Number(record[idKey]))
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteEntryButton icon={<DeleteOutlined />} />
                    </DeleteEntryPopconfirm>
                  </Popover>
                </Flex>
              ),
              width: "5rem",
              align: "right" as "left" | "right" | "center" | undefined,
            },
          ],
        ]}
        dataSource={dataSource.map((item) => ({
          ...item,
          key: item[idKey],
        }))}
        pagination={
          pagination ?? {
            showSizeChanger: true,
            pageSizeOptions: ["8", "12", "16", "32", "64", "128"],
            defaultPageSize: 8,
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} of ${total} items`;
            },
          }
        }
        loading={loading ?? false}
        sticky={sticky ?? true}
        scroll={scroll ?? { y: tableHeight - 3.5 * cellHeight }}
        size={size ?? "small"}
        style={{ width: "100%" }}
      />
    </TableContainer>
  );
};
