import routes from "routes/paths";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainContentWrapper } from "common/components/styled";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "authConfig";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error(error.message);
  }

  render() {
    if (this.state.hasError) {
      window.location.href = "/";
      return null;
    }

    return this.props.children;
  }
}

export const PageContents = () => {
  return (
    <ErrorBoundary>
      <MainContentWrapper>
        <Routes>
          {Object.values(routes).map((route) => {
            return route.protectedRoute ? (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                  >
                    <route.component />
                  </MsalAuthenticationTemplate>
                }
              />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            );
          })}
        </Routes>
      </MainContentWrapper>
    </ErrorBoundary>
  );
};
