import React, { useContext } from "react";
import { Avatar, Flex, MenuProps, Typography } from "antd";
import { Nav, Logo, StyledHorizontalMenu } from "common/components/styled";
import { images } from "common/enums";
import { useMobile } from "common/hooks";
import { sidebar } from "common/enums/sidebar";
import { useNavigate } from "react-router-dom";
import { State } from "common/types";
import { useIsAuthenticated } from "@azure/msal-react";
import { LogoutButton } from "common/components";
import { UserContext } from "common/context";

const { Title } = Typography;

export const Navbar = (props: { page: State<string> }) => {
  const isAuthenticated = useIsAuthenticated();
  const isMobile = useMobile();
  const navigate = useNavigate();
  const userInfo = useContext(UserContext);

  const goHome = () => {
    navigate("/");
    props.page.set("");
  };

  const navbarItems: MenuProps["items"] = [
    {
      key: "dataManagement",
      label: "Data Management",
      children: sidebar.map(({ path, ...items }) => items),
      popupOffset: [-14.5, 0],
      onClick: (e) => {
        props.page.set(e.key);
        const pageObject = sidebar.find((item) => item.key === e.key);
        if (pageObject !== undefined) {
          navigate(pageObject.path);
        }
      },
    },
    {
      key: "standby",
      label: "Standby",
      onClick: (e) => {
        props.page.set(e.key);
        navigate("/standby");
      },
    },
    {
      key: "unavailability",
      label: "Unavailability",
      onClick: (e) => {
        props.page.set(e.key);
        navigate("/unavailability");
      },
    },
    {
      key: "contractHours",
      label: "Hours Usage",
      onClick: (e) => {
        props.page.set(e.key);
        navigate("/contract-hours");
      },
    },
  ];

  return (
    <Nav>
      {isAuthenticated && (
        <Logo
          src={images.logo}
          preview={false}
          onClick={() => {
            goHome();
          }}
          style={{
            cursor: "pointer",
            height: "var(--navbar-height)",
            padding: "0.8rem 0",
          }}
        />
      )}

      {isAuthenticated && (
        <StyledHorizontalMenu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["dataManagement"]}
          selectable
          selectedKeys={[props.page.get]}
          items={navbarItems}
        />
      )}

      <Flex align="center" style={{ marginLeft: "auto", gap: "0.5rem" }}>
        {isAuthenticated && !isMobile && userInfo && (
          <>
            {userInfo.profilePhoto ? (
              <Avatar src={userInfo.profilePhoto} />
            ) : (
              <Avatar>
                {userInfo.givenName.charAt(0)}
                {userInfo.surname.charAt(0)}
              </Avatar>
            )}
            <Title level={2}>{userInfo.displayName}</Title>
          </>
        )}
        {isAuthenticated && <LogoutButton />}
      </Flex>
    </Nav>
  );
};
