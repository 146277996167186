import { WithDates, WithDateRange } from "common/types";

export const mapDateRangeToDates = <T extends WithDates<object>>(
  dateRangeObject: WithDateRange<T>
): T => {
  let firstDate: Date | undefined = undefined;
  let secondDate: Date | undefined = undefined;
  if (
    dateRangeObject.DateRange !== undefined &&
    dateRangeObject.DateRange.length === 2
  ) {
    firstDate = dateRangeObject.DateRange[0];
    secondDate = dateRangeObject.DateRange[1];
  }
  return {
    ...dateRangeObject,
    StartDate: firstDate,
    EndDate: secondDate,
  };
};

export const mapDatesToDateRange = <T extends WithDates<object>>(
  object: T
): WithDateRange<T> => {
  return {
    ...object,
    DateRange: [object.StartDate as Date, object.EndDate as Date],
  };
};
